<template>
  <div id="subpage-content">
    <ProjectsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarResourstrans") }}</h1>
        <div id="int-about-text">
          <img
            height="30"
            width="250"
            :src="getImageUrlbyName('Partn2')"
          />
          <span id="pad" v-html="$t('message.ResoursText1')"></span>
          <span id="pad" v-html="$t('message.ResoursText2')"></span>
        </div>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn">{{ $t("message.BuyProject") }} </v-btn>
              <div id="history-card-text">
                {{ $t("message.ConsultText") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
        <h1 id="naming-pad">{{ $t("message.Characteristics") }}</h1>
        <v-simple-table id = "font-st">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in res" :key="item.name">
              <td id = "left-c">{{ $t(item.name) }}</td>
              <td>{{ $t(item.text) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import Feedback from "../../../components/Feedback";
import ProjectsDrawer from "../../../views/Navigations/Projects/ProjectsDrawer";
export default {
  data() {
    return {
      res: [
        {
          name: "message.Customer",
          text: "message.ResoursName",
        },
        {
          name: "message.WebSite",
          text: "www.resourcetrans.ru",
        },
      ],
    };
  },
  components: {
    ProjectsDrawer,
    Feedback,
  },
};
</script>